import React from 'react';
import FijiPhoto from '../images/fiji.jpg';

function AboutAside({ gallery }) {
  return (
    <aside className="about__aside">
      <img src={FijiPhoto} alt="" style={{ width: '100%' }} />
      <section className="about__aside__content">
        {/* <h2>Bios:</h2>
    <p>
      <a href="#">50 Word</a>
      <br />
      <a href="#">100 Word</a>
      <br />
      <a href="#">500 Word</a>
    </p> */}
        {/* <h2>Press Photos:</h2>
        <p>
          {gallery
            ? gallery.media.map(({ title, description, image, slug }) => (
                <>
                  <a
                    title={description}
                    href={`${image.asset.url}?dl=nicolette-macleod-${slug.current}.${image.asset.extension}`}
                  >
                    {title}
                  </a>
                  <br />
                </>
              ))
            : null}
        </p> */}
        <h2>Email me:</h2>
        <p>
          <a href="mailto:music@nicolette.me">music@nicolette.me</a>
        </p>
      </section>
      {/* <img src={towelPhoto} alt="" style={{ width: '100%' }} /> */}
    </aside>
  );
}

export default AboutAside;
